import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'likelihoodSelect',
    'likelihoodSelectOpposite',
    'irremediabilitySelect',
    'irremediabilitySelectOpposite',
    'positiveOrNegativeOpposite',
  ];

  toggleLikelihood(event) {
    const value = event.target.value;
    const objectType = event.target.dataset.objectType;
    const likelihoodTarget =
      objectType === 'matter_evaluation'
        ? this.likelihoodSelectTarget
        : this.likelihoodSelectOppositeTarget;

    if (value === 'potential') {
      likelihoodTarget.classList.remove('hidden');
    } else {
      likelihoodTarget.classList.add('hidden');
    }
  }

  syncPositiveOrNegative(event) {
    if (this.hasPositiveOrNegativeOppositeTarget) {
      const value = event.target.value;
      const oppositeValue = value === 'positive' ? 'negative' : 'positive';
      const selectElement = this.positiveOrNegativeOppositeTarget;

      selectElement.value = oppositeValue;
    }

    this.toggleIrremediability(event);
  }

  toggleIrremediability(event) {
    // This is only triggered by the matter_evaluation form and not the opposite form
    const value = event.target.value;

    if (value === 'positive') {
      this.irremediabilitySelectTarget.classList.add('hidden');
      if (this.hasIrremediabilitySelectOppositeTarget) {
        this.irremediabilitySelectOppositeTarget.classList.remove('hidden');
      }
    } else {
      this.irremediabilitySelectTarget.classList.remove('hidden');
      if (this.hasIrremediabilitySelectOppositeTarget) {
        this.irremediabilitySelectOppositeTarget.classList.add('hidden');
      }
    }
  }
}

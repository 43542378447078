import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['modal'];

  toggleModal(e) {
    e.preventDefault();
    this.modalTarget.classList.toggle('hidden');
  }

  closeBackground(e) {
    if (e.target === this.modalTarget) {
      this.toggleModal(e);
    }
  }

  handleFormSubmit(event) {
    if (event.detail.success) {
      this.modalTarget.classList.toggle('hidden');
    }
  }
}

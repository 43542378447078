import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'icon'];
  static values = { openByDefault: Boolean };

  connect() {
    if (this.openByDefaultValue) {
      this.toggle();
    }
  }

  toggle() {
    console.log(this.contentTarget.classList);
    this.contentTarget.classList.toggle('d-none');
    console.log(this.contentTarget.classList);

    this.iconTarget.classList.toggle('arrow-up');
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'name'];

  connect() {
    this.updateName();
  }

  updateName() {
    const files = this.inputTarget.files;

    if (files.length > 0) {
      const fileNames = Array.from(files)
        .map((file) => file.name)
        .join(', ');

      this.nameTarget.hidden = false;
      this.nameTarget.innerHTML = fileNames;

      const impact_import_submit = document.getElementById('impact_import');
      const select_file = document.getElementById('select_file');

      if (impact_import_submit && select_file) {
        impact_import_submit.disabled = false;
        select_file.innerHTML = '';
        impact_import_submit.classList.add('button-secondary');
      }
    } else {
      this.nameTarget.hidden = true;
      this.nameTarget.innerHTML = '';
    }
  }
}
